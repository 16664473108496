.transparent {
  background-color: transparent;
}

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 100%;
  height: inherit !important;
  padding: 10px 20px;
  font-size: 14px;
  border-color: #ebedf2;
  border-radius: 4px;
}

.lineatest {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 12.5px;
  border-top: 1px solid #f1f1f1;
  display: block;
  margin-left: 10px;
  margin-right: 10px;
}

.react-autosuggest__input--focused {
  border: 1px solid #007bff;
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 42px;
  width: 100%;
  border: 1px solid #007bff;
  background-color: #fff;
  font-size: 14px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #007bff;
  color: #ffffff;
}

.card-pricing2.card-success {
  border-bottom-color: #19759f;
}

.card-pricing2.card-success:before {
  background: #19759f !important;
}

.card-pricing2.card-success .price-value:before,
.card-pricing2.card-success .value:before {
  border-left-color: #009688;
  border-top-color: #009688;
}

.card-pricing2.card-success .price-value:after,
.card-pricing2.card-success .value:after {
  border-bottom-color: #009688 !important;
}

.card-pricing2.card-success .price-value,
.card-pricing2.card-success .value {
  border-left-color: #009688;
}

.card-pricing2.card-success .price-value,
.card-pricing2.card-success .value {
  border-right-color: #009688;
}

.card-pricing2.card-success .price-value:after,
.card-pricing2.card-success .value:after {
  border-right-color: #009688;
}

.btn-border.btn-success {
  color: #009688 !important;
  border: 1px solid #009688 !important;
}

code {
  padding: 5px 10px;
  display: inline-block;
}

.container-a {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  grid-column-gap: 10px;
  justify-content: center;
  margin: 64px 0;
}

.field-a {
  width: 75px;
  height: 75px;
  font-size: 40px;
  text-align: center;
  outline: none;
  border-radius: 5px;
  border: 1px solid gray;
  transition-property: color, border, box-shadow, transform;
  transition-duration: 250ms;
}

.field-a:focus {
  outline: none;
  box-shadow: 0 0 7px rgba(#19769f, 0.5);
  border: 1px solid #19769f;
  transform: scale(1.05);
}

.field-a-complete {
  border: 1px solid #35d8a6;
  color: #35d8a6;
}

.field-a-complete[disabled] {
  background: rgba(#35d8a6, 0.1);
  opacity: 0.5;
  cursor: not-allowed;
}

.card-pricing2.card-disable {
  border-bottom-color: #c8c8c8;
}

.card-pricing2.card-disable:before {
  background: #c8c8c8 !important;
}

.btn-border.btn-disable {
  color: #c8c8c8 !important;
  border: 1px solid #c8c8c8 !important;
}

.card-pricing2 .pricing-content li.disableInactive:before {
  background: #c8c8c8 !important;
}

.menuEnabled {
  transform: none !important;
  margin-top: 62px;
  overflow: auto;
}
